import React from 'react'
// import HeroSection from '../../HeroSection'
import { Button } from '../../Button'
import { Link } from 'react-router-dom'
// import { homeObjThree } from './Data'
import './Contact.css'

function Contact({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  buttonLabel2,
  img,
  alt,
  imgStart,
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row',
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>Get in Touch</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  Contact Information
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  <strong>Phone: </strong>
                  <a href="tel:+19253742527"> (925)-374-2527</a>
                  <br />
                  <br />
                  <strong>Email: </strong>
                  <a href="mailto:ppwscheduling@gmail.com">ppwscheduling@gmail.com</a>
                  <br />
                </p>
              </div>
            </div>


            <div className='col'>
            <div className='home__hero-text-wrapper'>
              <div className='top-line'>Operational</div>
              <h1 className={lightText ? 'heading' : 'heading dark'}>
                Hours
              </h1>
              <p
                className={
                  lightTextDesc
                    ? 'home__hero-subtitle'
                    : 'home__hero-subtitle dark'
                }
              >
              <strong>Monday:</strong> 8 am - 6 pm
              <br />
              <strong>Tuesday:</strong> 8 am - 6 pm
              <br />
              <strong>Wednesday:</strong> 8 am - 6 pm
              <br />
              <strong>Thursday:</strong> 8 am - 6 pm
              <br />
              <strong>Friday:</strong> 8 am - 6 pm
              <br />
              <strong>Saturday:</strong> 8 am - 6 pm
              <br />
              <strong>Sunday:</strong> 8 am - 6 pm
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>


      {
        // <HeroSection {...homeObjThree} />
        // <section id="signin">
        //   <div className="container">
        //     <div class="left_section">
        //       <h4 class="access_heading">Get early access today</h4>
        //       <p class="access_text">
        //         It only takes a minute to sign up and our free starter tier is
        //         extremely generous. If you have any questions, our support team
        //         would be happy to help you.
        //       </p>
        //     </div>
        //     <div class="right_section">
        //       <input
        //         type="email"
        //         name=""
        //         id="email"
        //         placeholder="email@example.com"
        //       />
        //       <button class="btn btn_secondary">Get Started For Free</button>
        //     </div>
        //   </div>
        // </section>
    }
    </>
  )
}

export default Contact
