import React, { Component } from 'react';
import './TheTeam.css';

class TheTeam extends Component {
    render() {
        return (
          <div className="contact-body">
            <h1 className="about-us-heading">The Team</h1>
            <p className="team-description">
            With a rich Ukrainian heritage and the influence of our immigrant parents, Pacheco Pressure Washing embraces a strong work ethic and a passion for delivering top-notch pressure washing services. As two brothers who founded this company, we bring the values instilled in us by our Ukrainian roots to every project, ensuring exceptional quality and customer satisfaction. Trust our experienced team to revitalize your outdoor spaces with our unique blend of heritage, dedication, and expertise.
            </p>
            <div className="contact-grid">
                <div className="team-member">
                    <img
                        src="./images/dprof.jpg"
                        alt="avatar"
                        style={{ height: '250px' }}
                    />
                    <h3>Dennis</h3>
                    <p className="team-member-description">
                        I'm a college student studying aerospace engineering. Alongside my academic pursuits, I also have a strong passion for entrepreneurship and a relentless work ethic. As a member of the college basketball team, I've learned the importance of discipline, teamwork, and pushing myself to achieve my goals. These qualities translate into my work at Pacheco Pressure Washing, where I strive to deliver exceptional results with a personal touch. I approach each project with the same determination and attention to detail that I bring to my studies and sports. Customer satisfaction and a job well done are my top priorities.
                    </p>
                </div>
                <div className="team-member">
                    <img
                        src="./images/zprof.jpg"
                        alt="avatar"
                        style={{ height: '250px' }}
                    />
                    <h3>Zach</h3>
                    <p className="team-member-description">
                        I recently graduated from high school with a passion for making a difference. Alongside our exceptional pressure washing services, I also aspire to become an electrician. Attention to detail is my forte, and I take pride in delivering meticulous work. However, my dedication extends beyond my professional goals. I have a deep-rooted love for community involvement and lending a helping hand wherever I can. Joining Pacheco Pressure Washing allows me to combine my entrepreneurial spirit with my desire to contribute to the community. With a strong work ethic and a genuine commitment to both our clients and the greater good, I am thrilled to be part of this team as we make a positive impact together.
                    </p>
                </div>
            </div>
          </div>
        );
    }
}

export default TheTeam;
