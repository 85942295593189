export const patiosDrivewaysAndWalkways = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Patios, Driveways and Walkways',
  description:
    'Trust us to clean driveways and walkways, eliminating unsightly stains and improving safety with a clean and slip-resistant surface. Choose our pressure washing services for a sparkling clean and inviting space that leaves a lasting impression.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/pavement.jpg',
  alt: 'Credit Card',
  linkTo: '/contact'
}

export const decksAndFences = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Decks and Fences',
  description:
    'Indulge in the ultimate relaxation and entertainment experience on your decks and fences. Our dedicated team will restore them to their former glory, eradicating dirt and stains, so you can unwind in a pristine outdoor oasis that exudes charm and tranquility.',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/deck3.jpg',
  alt: 'Vault',
  linkTo: '/contact'
}

export const exteriorOfBuildings = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Exterior of Buildings',
  description:
    "Make a powerful first impression with a beautifully revitalized exterior. Our professional pressure washing services breathe new life into your buildings, removing dirt, grime, and unsightly marks. Stand out from the crowd and showcase your property's impeccable appearance with our expert touch.",
  buttonLabel: 'Start Now',
  imgStart: '',
  img: 'images/home.jpg',
  alt: 'Vault',
  linkTo: '/contact'
}

export const poolAreas = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Pool Areas',
  description:
    'Dive into a revitalized and inviting pool area that exudes freshness and serenity. Our experienced team will effortlessly eliminate built-up grime and algae, ensuring your pool area becomes a refreshing oasis that invites relaxation and creates unforgettable memories.',
  buttonLabel: 'Contact Us',
  imgStart: 'start',
  img: 'images/pool.jpg',
  alt: 'Vault',
  linkTo: '/contact'
}
