export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Locally Owned and Operated',
  headline: 'Pacheco Pressure Washing',
  description:
    'Contact us to recieve a FREE quote today!',
  buttonLabel: 'Recieve a Free Quote',
  // buttonLabel2: 'Learn More',
  imgStart: '',
  // img: 'images/svg-1.svg',
  alt: 'Credit Card',
  linkTo: '/contact'
}

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'OUR SERVICES',
  headline: 'Pressure Washing Services',
  description:
    'Our pressure washing services cater to various areas, including driveways, walkways, decks, patios, and the exterior of buildings. We specialize in eliminating stains, dirt, and grime, ensuring a clean and well-maintained appearance. Whether you need to enhance the safety and aesthetics of your driveways and walkways or revive your decks, patios, and pool areas, our professional team is dedicated to delivering exceptional results that leave a lasting impression.',
  buttonLabel: 'Learn More',
  imgStart: '',
  // img: 'images/svg-5.svg',
  alt: 'Vault',
  linkTo: '/services'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'WHY US?',
  headline: 'Clean. Reliable. Professional. Affordable.',
  description:
    "At Pacheco Pressure Washing, we are driven by a relentless ambition to exceed our customers' expectations. With our meticulous attention to detail, top-notch equipment, and skilled team, we guarantee to restore the pristine condition of your property, leaving it looking fresh, vibrant, and protected. Choose our service for exceptional results that will enhance the beauty, value, and longevity of your home or business.",
  buttonLabel: 'Recieve a Free Quote',
  imgStart: 'start',
  img: 'images/solar1.jpg',
  alt: 'Vault',
  linkTo: '/contact'
}

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'PAYMENT',
  headline: 'Flexible Payment Options',
  description:
    'We prioritize your convenience by offering a variety of payment options, including major credit cards like Visa, Mastercard, and American Express, popular digital platforms such as Venmo, Zelle, and PayPal, as well as traditional methods like cash and personal checks. Rest assured, we strive to accommodate your preferred payment method to ensure a seamless transaction experience.',
  buttonLabel: 'Recieve a Free Quote',
  imgStart: 'start',
  // img: 'images/svg-8.svg',
  alt: 'Vault',
  linkTo: '/contact'
}
