import React from 'react'
import HeroSection from '../../HeroSection'
// import Pricing from '../../Pricing'
import Testimonials from '../Testimonials/Testimonials'
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data'
import { patiosDrivewaysAndWalkways, decksAndFences, exteriorOfBuildings, poolAreas } from '../Services/Data'
import './Home.css'
// import StatisticChart from './aaa'
import TheTeam from '../MeetTheTeam/TheTeam'
import { Button } from '../../Button'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <>
      <HeroSection {...homeObjOne} />
      {
        // <StatisticChart />
      }
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjTwo} />

      <div className="heading-container">
        <h1>Popular Services</h1>
      </div>

      <HeroSection {...patiosDrivewaysAndWalkways} />
      <HeroSection {...decksAndFences} />
      <HeroSection {...exteriorOfBuildings} />
      <HeroSection {...poolAreas} />

      <Link to='/services'>
        <div className="all-services-btn">
          <Button buttonStyle='btn--outline' buttonSize='btn--wide'>
            View All Services
          </Button>
        </div>
      </Link>

      <HeroSection {...homeObjFour} />
      <TheTeam />
      <Testimonials />
    </>
  )
}

export default Home
