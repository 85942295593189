const people = [
  {
    id: 1,
    image:
      'images/2.jpg',
    name: 'Sophia Reynolds',
    title: 'San Francisco',
    quote:
      'I was absolutely blown away by the exceptional service provided by this pressure washing company. They transformed my dirty and grimy exterior surfaces into sparkling clean areas. Their attention to detail and professionalism deserve high praise!',
  },
  {
    id: 2,
    image:
      'images/4.jpg',
    name: 'Samuel Parker',
    title: 'San Jose',
    quote:
      'I highly recommend this pressure washing service for deck cleaning. They went above and beyond to remove tough stains and dirt buildup on my wooden deck. The results are outstanding, and I can enjoy my outdoor space with confidence and pride.',
  },
  {
    id: 3,
    image:
      'images/3.jpg',
    name: 'Sebastian Ramirez',
    title: 'Oakland',
    quote:
      'I had stubborn oil stains on the driveway that seemed impossible to remove, but this pressure washing service worked wonders! They skillfully eliminated the stains, leaving my driveway looking brand new. I am impressed!',
  },
  {
    id: 4,
    image:
      'images/1.jpg',
    name: 'Lila Petrovsky',
    title: 'Walnut Creek',
    quote:
      'I could not be happier with the results of the pressure washing service I received from this company. They effortlessly removed years of dirt and grime from my driveways and walkways, making them look brand new again. Their friendly and knowledgeable staff made the whole experience a breeze.',
  },
];

export default people;
