import React from 'react'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import './HeroSection.css'

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  buttonLabel2,
  img,
  alt,
  imgStart,
  linkTo
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row',
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>

                <Link to={linkTo} onClick={scrollToTop}>
                  <div className="btn-landing">
                    <Button buttonSize='btn--wide' buttonColor='blue'>
                      {buttonLabel}
                    </Button>
                  </div>
                  {
                    buttonLabel2
                    ? <div className="btn-landing">
                        <Button buttonSize='btn--wide' buttonColor='blue'>
                          {buttonLabel2}
                        </Button>
                      </div>
                    : <></>
                  }

                </Link>
              </div>
            </div>
            {img
            ? <div className='col'>
               <div className='home__hero-img-wrapper'>
                 <img src={img} alt={alt} className='home__hero-img' />
               </div>
             </div>
            : <></>
          }
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
