import React from 'react'
import './Footer.css'
import { Button } from '../../Button'
import { Link } from 'react-router-dom'
import {
  FaFacebook,
  FaInstagram,
  FaYelp,
} from 'react-icons/fa'
import { SiNextdoor } from "react-icons/si";
import { MdFingerprint } from 'react-icons/md'

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function Footer() {
  return (
    <div className='footer-container'>
      {
      //   <section className='footer-subscription'>
      //   <p className='footer-subscription-heading'>
      //     Join our exclusive membership to receive the latest news and trends
      //   </p>
      //   <p className='footer-subscription-text'>
      //     You can unsubscribe at any time.
      //   </p>
      //   <div className='input-areas'>
      //     <form>
      //       <input
      //         className='footer-input'
      //         name='email'
      //         type='email'
      //         placeholder='Your Email'
      //       />
      //       <Button buttonStyle='btn--outline'>Subscribe</Button>
      //     </form>
      //   </div>
      // </section>
    }
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/contact' onClick={scrollToTop}>Hours</Link>
            <Link to='/services' onClick={scrollToTop}>Services</Link>
            <Link to='/services' onClick={scrollToTop}>Reviews</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact' onClick={scrollToTop}>Contact</Link>
            <Link to='/contact' onClick={scrollToTop}>Careers</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
      {
          // <div className='footer-link-items'>
          //   <h2>Videos</h2>
          //   <Link to='/'>Submit Video</Link>
          //   <Link to='/'>Ambassadors</Link>
          //   <Link to='/'>Agency</Link>
          //   <Link to='/'>Influencer</Link>
          // </div>
      }
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a className='social-icon-link' href='https://www.facebook.com/profile.php?id=100092849890201&sk=grid' target='_blank' rel='noopener noreferrer' aria-label='Facebook'>Facebook</a>
            <a className='social-icon-link' href='https://www.instagram.com/pachecopressurewashing/' target='_blank' rel='noopener noreferrer' aria-label='Instagram'>Instagram</a>
            <a className='social-icon-link' href='https://nextdoor.com/business-profile/preview/?init_source=profile_switcher&active_page_id=64005219&profile_id=83467673' target='_blank' rel='noopener noreferrer' aria-label='Nextdoor'>Nextdoor</a>
            <a className='social-icon-link' href='https://s.yelp.com/wYIzRRyRec' target='_blank' rel='noopener noreferrer' aria-label='Yelp'>Yelp</a>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' onClick={scrollToTop} className='social-logo'>
              <img className='navbar-icon' src='./logo.png'/>
              <span className='logo-text'>Pacheco Pressure Washing</span>
            </Link>
          </div>
          <small className='website-rights'>PPW © 2023</small>
          <div className='social-icons'>
            <a
              className='social-icon-link'
              href='https://www.facebook.com/profile.php?id=100092849890201&sk=grid'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Facebook'
            >
              <FaFacebook />
            </a>
            <a
              className='social-icon-link'
              href='https://www.instagram.com/pachecopressurewashing/'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Instagram'
            >
              <FaInstagram />
            </a>
            <a
              className='social-icon-link'
              href='https://nextdoor.com/business-profile/preview/?init_source=profile_switcher&active_page_id=64005219&profile_id=83467673'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Nextdoor'
            >
              <SiNextdoor />
            </a>
            <a
              className='social-icon-link'
              href='https://s.yelp.com/wYIzRRyRec'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Yelp'
            >
              <FaYelp />
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer
