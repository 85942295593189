import React from 'react'
import HeroSection from '../../HeroSection'
import Pricing from '../../Pricing'
// import { Button } from '../../Button'
// import { Link } from 'react-router-dom'
import { patiosDrivewaysAndWalkways, decksAndFences, exteriorOfBuildings, poolAreas } from './Data'

function Services() {
  return (
    <>
      <HeroSection {...patiosDrivewaysAndWalkways} />
      <HeroSection {...decksAndFences} />
      <HeroSection {...exteriorOfBuildings} />
      <HeroSection {...poolAreas} />
      {
        // <Pricing />
      }
    </>
  )
}

export default Services
